import styled, {createGlobalStyle} from 'styled-components'
import { GlobalColors, GlobalFonts} from "../../styles/GlobalStyles";
import guide from '../../images/guide.svg'
import devActive from '../../images/dev-active.svg'

export const GlobalStyleSlider = createGlobalStyle`
  .rec-slider,
  .rec-slider-container{
    margin: 0 !important;
  }
  .rec-carousel-wrapper{
    position: relative;
    
    @media(min-width: 300px) and (max-width: 767px){
      
    }
    @media(min-width: 768px) and (max-width: 1023px){
      
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: -80px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: -80px;
    }
    @media(min-width: 1408px){
      top: -80px;      
    }
  }
  .cls-slider .rec-item-wrapper{
    height: 550px;
    max-height: 550px;
    
    @media(min-width: 300px) and (max-width: 767px){
      height: 750px;
      max-height: 750px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      height: 750px;
      max-height: 750px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      
    }
    @media(min-width: 1920px){
      
    }
  }
  .cls-slider .rec-item-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    @media(min-width: 300px) and (max-width: 767px){
      
    }
    @media(min-width: 768px) and (max-width: 1023px){
      
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      /*padding-left: 180px !important;*/
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      
    }
    @media(min-width: 1920px){
      
    }
  } 
    
  .cont-slider-arrow-l,
  .cont-slider-arrow-r{
    z-index: 999;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    .cont-slider-arrow-l{
      position: absolute;
      top: -40px;
      left: 5%;
    }
    .cont-slider-arrow-r{
      position: absolute;
      top: -40px;
      left: calc(15% + 35px);
    }
  }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .cont-slider-arrow-l{
      position: absolute;
      top: 0;
      left: 10%;
    }
    .cont-slider-arrow-r{
      position: absolute;
      top: 0;
      left: calc(15% + 20px);
    }
  }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .cont-slider-arrow-l{
      position: absolute;
      top: 0;
      left: 10%;
    }
    .cont-slider-arrow-r{
      position: absolute;
      top: 0;
      left: calc(15% + 20px);
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .cont-slider-arrow-l{
      position: absolute;
      top: 0;
      left: 10%;
    }
    .cont-slider-arrow-r{
      position: absolute;
      top: 0;
      left: calc(15% + 20px);
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .cont-slider-arrow-l{
      position: absolute;
      top: 0;
      left: 10%;
    }
    .cont-slider-arrow-r{
      position: absolute;
      top: 0;
      left: calc(15% + 20px);
    }
  }
  @media(min-width: 1920px){
    .cont-slider-arrow-l{
      position: absolute;
      top: 0;
      left: 10%;
    }
    .cont-slider-arrow-r{
      position: absolute;
      top: 0;
      left: calc(15% + 20px);
    }
  }
`

export const ContDevActive = styled.div`
  position: absolute;
  width: 688px;
  height: 500px;
  
  background-image: url(${devActive});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 436.8px;
    top: -6%;
    left: 10px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    top: 2%;
    left: 15%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    top: 45px;
    left: 80px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    top: 40px;
    left: 160px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    top: 45px;
    left: 205px;
  }
  @media(min-width: 1920px){
    top: 48px;
    left: 375px;
  }
  
`

export const ContSlider = styled.div`
  position: relative;
  padding: 100px 0;
  
  width: 100%;
  height: auto;
  
  background-image: url(${guide});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-bottom: 0 !important;
    background-position: center 150px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    background-position: 0 200px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    background-position: bottom center;
  }
`

export const ContItemsCounter = styled.div`
  top: 0;
  margin-right: 50px;
  width: 100%;
  height: auto;
  
  /*display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;*/
`
export const ItemsCounter = styled.div`
  position: absolute;
  top: 0;
  left: 12.5%;
  
  font: 20px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorLinkHover};
  
  @media(min-width: 300px) and (max-width: 767px){
    top: -40px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
