import React from 'react';
import {Column, Columns} from 'bloomer';
import {
  ContInfoItemImg,
  InfoItemImg,
  ContInfoItem,
  ContItemCarouselImg,
  ItemCarouselImg,
  ItemCarousel,
  GlobalStyleItemSlider, ContDetailsInfoTitle, ContDetailsInfoDesc, ContDetailsInfo, LinkInfoItemImg,
} from './stylesItemSlider';

const ItemSlider = ({id, imageDev, imageName, linkDev, infoDesc, infoCoordenadas, linkMaps, status}) => {

  return (
    <ItemCarousel id={`ic-${id}`} className={'item-carousel ic-active'}>
      <GlobalStyleItemSlider/>
      <Columns isMultiline={true} className="full-height full-width global-columns-paddign-no global-columns-margin-no">
        <Column
          isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}
          className={'no-display-mobile global-columns-paddign-no'}
        >
          &nbsp;
        </Column>
        <Column
          isSize={{mobile: 12, tablet: 8, desktop: 6, widescreen: 6}}
          className={'global-columns-paddign-no'}
        >
          <ContItemCarouselImg>
            <LinkInfoItemImg to={`/${linkDev}/`}>
              <ItemCarouselImg bgImg={imageDev} id={`img-${id}`} className={'img-item-carousel img-active'}/>
            </LinkInfoItemImg>
          </ContItemCarouselImg>
        </Column>
        <Column
          isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}
          className={'no-display-mobile global-columns-paddign-no'}
        >
          &nbsp;
        </Column>
        <Column
          isSize={{mobile: 12, tablet: 4, desktop: 4, widescreen: 4}}
          className={'global-columns-paddign-no'}
        >
          <ContInfoItem>
            <ContDetailsInfo>
              <ContInfoItemImg>
                <LinkInfoItemImg to={`/${linkDev}/`}>
                  <InfoItemImg bgImg={imageName}/>
                </LinkInfoItemImg>
              </ContInfoItemImg>
              <ContDetailsInfoTitle>Ubicación</ContDetailsInfoTitle>
              <ContDetailsInfoDesc>{infoDesc}</ContDetailsInfoDesc>
              <ContDetailsInfoTitle>Coordenadas</ContDetailsInfoTitle>
              <ContDetailsInfoDesc>
                <a href={linkMaps} target="_blank" rel="noreferrer">{infoCoordenadas}</a>
              </ContDetailsInfoDesc>
              <ContDetailsInfoTitle>Status</ContDetailsInfoTitle>
              <ContDetailsInfoDesc>{status}</ContDetailsInfoDesc>
            </ContDetailsInfo>
          </ContInfoItem>
        </Column>
      </Columns>
    </ItemCarousel>
  );
};

export default ItemSlider;
