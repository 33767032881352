import styled, {createGlobalStyle} from 'styled-components';
import {Link} from "gatsby";
import {GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const GlobalStyleItemSlider = createGlobalStyle`
  /*.ic-active,*/
  .img-active{
    margin-top: 0;
    width: 440px;
    height: 440px;
    transition: margin-top, width, height, 1s ease-out;
    
    display: flex;
    align-items: center;
    justify-content: center;
  
    @media(min-width: 300px) and (max-width: 767px){
      width: 300px;
      height: 300px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      
    }
    @media(min-width: 1408px){
        
    }
  }
  /*.ic-no-active,*/
  .img-no-active{
    bottom: 0;
    
    margin-top: 0;
    width: 200px !important;
    height: 200px !important;
    
    transition: margin-top, width, height, 1s ease-in;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    /*@media(min-width: 300px) and (max-width: 767px){
      width: 160px !important;
      height: 160px !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      width: 180px !important;
      height: 180px !important;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      width: 200px !important;
      height: 200px !important;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      width: 200px !important;
      height: 200px !important;
    }
    @media(min-width: 1408px){
      width: 220px !important;
      height: 220px !important;  
    }*/
  }
`

export const ItemCarousel = styled.div`
  position: relative;
  
  margin-top: 0;
  width: 100%;
  height: 440px;
  transition: margin-top, width, height, 1s ease-out;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  /*&:hover{
    margin-top: 0;
    width: 200px !important;
    height: 200px !important;
    
    background-color: #cc0f35;
    
    transition: margin-top, width, height, 1s ease-in;
    
    display: flex;
    align-items: center;
    justify-content: center;
  }*/
  
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 750px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 750px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 440px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 440px;
  }
  @media(min-width: 1408px){
    height: 440px;
  }
`
export const ContItemCarouselImg = styled.div`
  width: 100%;
  height: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ItemCarouselImg = styled.div`
  background-image: url(${props => props.bgImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 440px;
    height: 440px;    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`

export const ContInfoItem = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  
  @media(min-width: 300px) and (max-width: 767px){
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ContInfoItemImg = styled.div`
  
`
export const LinkInfoItemImg = styled(Link)`
  
`
export const InfoItemImg = styled.div`
  width: 275px;
  height: 70px;
  
  background-image: url(${props => props.bgImg});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 100%;
    height: 70px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ContDetailsInfo = styled.div`
  position: relative;
  width: 70%;
  margin-top: -50px;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-top: 50px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 300px;
    margin-top: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ContDetailsInfoTitle = styled.div`
  margin-top: 15px;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  color: ${GlobalColors.colorLinkHover};
`
export const ContDetailsInfoDesc = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  font-size: 20px;
  color: ${GlobalColors.colorPrimary};

  & > a {
    font-family: ${GlobalFonts.fontRegular};
    font-size: 20px;
    color: ${GlobalColors.colorPrimary};
  }
  & > a:hover {
    font-family: ${GlobalFonts.fontRegular};
    font-size: 20px;
    color: ${GlobalColors.colorLinkHover};
  }
`
