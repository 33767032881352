import React, {useState} from 'react';
import { Link } from "gatsby";
import {Column, Columns, Container} from "bloomer";
import Carousel, { consts } from 'react-elastic-carousel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {
  ContBetas,
  ContBienvenido,
  ContDibujo,
  ContDibujoCirculo,
  ContDibujoLinea,
  ContBetasDetails,
  ContBetasDesc,
  ContInicio,
  ContLema,
  ContBetasLema,
  ContSliderBetas,
  GlobalStyle,
  ImgLogoAzul,
  ContBetasBye,
  ContSliderDevs,
  ContLoTieneTodo,
  TextoLoTieneTodo,
  TextoImaginasParaiso,
  TextoDescubrelo, ContItemLifeStyle, ContItemLifeStyleDesc, ContItemLifeStyleTitle,
  ContImgTodo, ImgTodo
} from './styles';
import LogoAzul from '../../images/logo-the-ocean-group-azul.svg';
import CarouselPrin from "../Carousel";
import Slider from "../Slider";
import imgTodo1 from '../../images/home/slider/01.jpg';
import imgTodo2 from '../../images/home/slider/02.jpg';
import imgTodo3 from '../../images/home/slider/03.jpg';
import imgTodo4 from '../../images/home/slider/04.jpg';
import imgTodo5 from '../../images/home/slider/05.jpg';
import imgTodo6 from '../../images/home/slider/06.jpg';
import imgTodo7 from '../../images/home/slider/07.jpg';
import lifeStyle1 from '../../images/life-styles-1.jpg';
import lifeStyle2 from '../../images/life-styles-2.jpg';
import lifeStyle3 from '../../images/life-styles-3.jpg';
import icoTOGWhite from '../../images/ico-the-ocean-group-blanco.svg';
import FooterCopmp from "../Footer";
import { FadeInUp, SlideInUp } from 'animate-css-styled-components';
import ModalGuzbarraf from '../UI/Modal';
import {GlobalColors} from '../../styles/GlobalStyles';
import {GlobalStyleModal} from '../UI/Modal/styles';


const langBienvenido = {
  en: 'Welcome',
  es: 'Bienvenido'
};
const langLema = {
  en: `We are Ocean, a group that is committed to transforming life on the Oaxacan coast. <br/> <br/> We are dedicated to real estate development, administration and management of properties, building on the coast to provide new spaces with different alternatives, for travelers, to live and or to invest.`,
  es: `Somos Ocean un grupo que apostamos por la transformación de la  vida en la costa Oaxaqueña. <br/><br/>Nos dedicamos al desarrollo inmobiliario, administración y gestión de inmuebles, construyendo en la costa para brindar nuevos espacios con diferentes alternativas, para viajeros, para vivir y o invertir.`
};

const langBetasLema = {};
const langBetasDesc = {
  en: `It is the epicenter of the Oaxacan coast for its majestic ocean and spectacular beaches. It offers us a great quality of life that goes from the rustic romanticism of the local hospitality of the tip, to go further, in the near future to the pleasure of the expensive luxuries of modern life, in Playa Bacocho with the Oxean development,
for those who are really looking for an exclusive, Premium experience, in harmony with life and nature on the Oaxacan coast. Puerto Escondido is not only a tourist destination, it is your destination beyond your dreams!.`
  ,
  es: `Es el epicentro de la costa Oaxaqueña por su majestuoso océano y espectaculares playas. Nos ofrece una gran calidad de vida que va del romanticismo rústico de la hospitalidad local de la punta, a ir más allá, en un futuro próximo al placer de los costosos lujos de la vida moderna, en Playa Bacocho con el desarrollo Oxean, para aquellos que realmente buscan una experiencia exclusiva, Premium, en armonía con la vida y la naturaleza en la costa Oaxaqueña. Puerto Escondido no es solamente un destino turístico ¡es tu destino más allá de tus sueños!`
};
const langPuertoLema = {
  es: `El nuevo habito costero`,
  en: `The new coastal habit`,
}

const HomeComp = (props) => {
  const lang = props.pageContext.langKey;
  const [showModalPuertotieneTodo, setShowModalPuertotieneTodo] = useState(false);

  const myArrowPuertoTieneTodo = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV
      ? <FontAwesomeIcon icon={faChevronLeft} color={GlobalColors.colorMenuPrimary} />
      : <FontAwesomeIcon icon={faChevronRight} color={GlobalColors.colorMenuPrimary} />;

    const side = type === consts.PREV ? 'l' : 'r';

    return (
      <div className={`cont-slider-arrow-carousel-in-modal-${side}`}>
        <button onClick={onClick} disabled={isEdge} className={'button-arrow-carousel-in-modal'}>
          {pointer}
        </button>
      </div>
    )
  }

  const onShowModalPuertoTieneTodo = () => {
    console.log('onShowModalPuertoTieneTodo');
    setShowModalPuertotieneTodo(true);
  }
  const onHideModalPuertoTieneTodo = () => {
    console.log('onHideModalPuertoTieneTodo');
    setShowModalPuertotieneTodo(false);
  }

  return (

    <div className="cont-page page-home">
      <GlobalStyle/>
      <Container>
        <Columns className="columns-responsive full-height">
          <Column
            className="no-display-mobile"
            isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>&nbsp;</Column>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 10, widescreen: 10}}>
            <ContInicio>
              <FadeInUp duration="0.8s" delay="1s">
                <ContBienvenido>{langBienvenido[lang]}</ContBienvenido>
              </FadeInUp>
              <FadeInUp duration="1.3s" delay="1.5s">
                <ContLema
                  dangerouslySetInnerHTML={{ __html: langLema[lang] }}
                />
              </FadeInUp>
              <FadeInUp duration="1.2s" delay="2.2s">
                <ContDibujo>
                  <ContDibujoLinea/>
                  <ContDibujoCirculo/>
                </ContDibujo>
              </FadeInUp>
            </ContInicio>
          </Column>
          <Column
            className="no-display-mobile"
            isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>&nbsp;</Column>
        </Columns>
      </Container>

      <ContBetas>
        <Columns
          className="columns-responsive columns-margin-side-no full-height"
          isMultiline={true}
        >

          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <ContBetasDetails>
              <ImgLogoAzul src={LogoAzul}/>
              <ContBetasLema>
                <div>Puerto Escondido</div>
                <div>{langPuertoLema[lang]}</div>
              </ContBetasLema>
              <ContBetasDesc>
                {langBetasDesc[lang]}
              </ContBetasDesc>
              <ContBetasBye>VIVE EN PUERTO</ContBetasBye>
            </ContBetasDetails>
          </Column>

          <Column
            className={'columns-paddign-no-global'}
            isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}
          >
            <ContSliderBetas>
              <CarouselPrin/>
            </ContSliderBetas>
          </Column>

        </Columns>
      </ContBetas>


      <ContSliderDevs>
        <Slider/>
      </ContSliderDevs>


      <Container>
        <Columns>
          <Column>
            <ContLoTieneTodo>
              <TextoLoTieneTodo><span>PUERTO</span> LO TIENE TODO</TextoLoTieneTodo>
              <TextoImaginasParaiso>¿Cómo te imaginas el paraíso?</TextoImaginasParaiso>
              <TextoDescubrelo>DESCÚBRELO</TextoDescubrelo>
            </ContLoTieneTodo>
          </Column>
        </Columns>
      </Container>



      <ContImgTodo onClick={() => onShowModalPuertoTieneTodo()}>
        <ImgTodo src={imgTodo1} alt="puerto-escondido-puerto-piedra" />
      </ContImgTodo>
      {
        showModalPuertotieneTodo &&
        <ModalGuzbarraf onClose={() => onHideModalPuertoTieneTodo()}>
          <GlobalStyleModal/>
          <Carousel
            isRTL={false}
            itemsToScroll={1}
            itemsToShow={1}
            itemPadding={[0, 0]}
            renderArrow={myArrowPuertoTieneTodo}
            outerSpacing={0}
            itemPosition={consts.START}
            className={'rec-carousel-wrapper-carousel-in-modal'}
          >
            <div id={1}>
              <img src={imgTodo1}/>
            </div>
            <div id={2}>
              <img src={imgTodo2}/>
            </div>
            <div id={3}>
              <img src={imgTodo3}/>
            </div>
            <div id={4}>
              <img src={imgTodo4}/>
            </div>
            <div id={5}>
              <img src={imgTodo5}/>
            </div>
            <div id={6}>
              <img src={imgTodo6}/>
            </div>
            <div id={7}>
              <img src={imgTodo7}/>
            </div>
          </Carousel>
        </ModalGuzbarraf>
      }



      <Container style={{marginTop: 50}}>
        <Columns
          className="columns-responsive columns-margin-side-no full-height"
          isMultiline={true}
        >
          <Column
            className={'columns-paddign-no-global'}
            isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}
          >
            <Link to={'/lifestyle'}>
              <ContItemLifeStyle
                bgImg={lifeStyle1}
              >
                <ContItemLifeStyleDesc>
                  <ContItemLifeStyleTitle>Life Style</ContItemLifeStyleTitle>
                  <div><img src={icoTOGWhite} alt={'The Ocean Group'} /></div>
                </ContItemLifeStyleDesc>
              </ContItemLifeStyle>
            </Link>
          </Column>
          <Column
            className={'columns-paddign-no-global'}
            isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}
          >
            <Link to={'/lifestyle'}>
              <ContItemLifeStyle
                bgImg={lifeStyle2}
              >
                <ContItemLifeStyleDesc>
                  <ContItemLifeStyleTitle>Life Style</ContItemLifeStyleTitle>
                  <div><img src={icoTOGWhite} alt={'The Ocean Group'} /></div>
                </ContItemLifeStyleDesc>
              </ContItemLifeStyle>
            </Link>
          </Column>
          <Column
            className={'columns-paddign-no-global'}
            isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}
          >
            <Link to={'/lifestyle'}>
              <ContItemLifeStyle
                bgImg={lifeStyle3}
              >
                <ContItemLifeStyleDesc>
                  <ContItemLifeStyleTitle>Life Style</ContItemLifeStyleTitle>
                  <div><img src={icoTOGWhite} alt={'The Ocean Group'} /></div>
                </ContItemLifeStyleDesc>
              </ContItemLifeStyle>
            </Link>
          </Column>
        </Columns>
      </Container>


      <FooterCopmp/>

    </div>
  )
}

export default HomeComp
