import React from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {
  ContCarousel,
  ContItemsCounter,
  GlobalStyleCarousel,
  ItemCarousel,
  ItemsCounter,
  ItemsCounterLine,
} from './styles';
import img1 from '../../images/carousel/carousel-1.jpg'
import img2 from '../../images/carousel/carousel-2.jpg'
import img3 from '../../images/carousel/carousel-3.jpg'
import img4 from '../../images/carousel/carousel-4.jpg'
import img5 from '../../images/carousel/carousel-5.jpg'
import img6 from '../../images/carousel/carousel-6.jpg'
import img7 from '../../images/carousel/carousel-7.jpg'

const CarouselPrin = () => {

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />;
    const side = type === consts.PREV ? 'l' : 'r';
    return (
      <div className={`cont-button-arrow-${side}`}>
        <button onClick={onClick} disabled={isEdge} className={'button-arrow'}>
          {pointer}
        </button>
      </div>
    )
  }
  return (
    <ContCarousel>
      <GlobalStyleCarousel/>
      <Carousel
        isRTL={false}
        itemsToScroll={1}
        itemsToShow={1.2}
        itemPadding={[0, 10]}
        renderArrow={myArrow}
        /*enableAutoPlay autoPlaySpeed={3000}*/
        renderPagination={({ pages, activePage, onClick }) => {

          //console.log('pages => ', pages);
          //console.log('activePage => ', activePage);

          return (
            <ContItemsCounter>
              <ItemsCounter>
                {activePage + 1}/{pages.length}
              </ItemsCounter>
              <ItemsCounterLine/>
            </ContItemsCounter>
          )
        }}
      >
        <ItemCarousel className={'lazy-image img-n'} bgImg={img1} />
        <ItemCarousel className={'lazy-image img-l'} bgImg={img2} />
        <ItemCarousel className={'lazy-image img-n'} bgImg={img3} />
        <ItemCarousel className={'lazy-image img-l'} bgImg={img4} />
        <ItemCarousel className={'lazy-image img-n'} bgImg={img5} />
        <ItemCarousel className={'lazy-image img-l'} bgImg={img6} />
        <ItemCarousel className={'lazy-image img-n'} bgImg={img7} />
      </Carousel>
    </ContCarousel>
  )
}

export default CarouselPrin
