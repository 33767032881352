import styled, {createGlobalStyle} from 'styled-components'
import { GlobalColors, GlobalFonts} from "../../styles/GlobalStyles";

export const GlobalStyleCarousel = createGlobalStyle`
  .rec-slider-container{
    margin: 0 !important;
  }
  .rec-carousel-item{
    display: flex;
    align-items: flex-end;
  }
  
  .button-arrow{
    font-size: 18px;
    color: ${GlobalColors.colorSecondary};
    cursor: pointer;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    .rec-carousel-wrapper{
      position: relative;
      bottom: 50px;
    }
    .cont-button-arrow-l{
      position: absolute;
      bottom: 0;
      left: 20%;
    }
    .cont-button-arrow-r{
      position: absolute;
      bottom: 0;
      left: calc(20% + 20px);
    }
    .button-arrow{
      font-size: 20px;
      /*color: ${GlobalColors.colorLight};*/
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .rec-carousel-wrapper{
      position: relative;
      bottom: 50px;
    }
    .cont-button-arrow-l{
      position: absolute;
      bottom: 0;
      left: 20%;
    }
    .cont-button-arrow-r{
      position: absolute;
      bottom: 0;
      left: calc(20% + 20px);
    }
    .button-arrow{
      font-size: 20px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .rec-carousel-wrapper{
      position: relative;
      bottom: 50px;
    }
    .img-n{
      width: 300px !important;
      height: 450px !important;
    }
    .img-l{
      width: 300px !important;
      height: 500px !important;
    }
    .cont-button-arrow-l{
      position: absolute;
      bottom: 0;
      left: 20%;
    }
    .cont-button-arrow-r{
      position: absolute;
      bottom: 0;
      left: calc(20% + 20px);
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-n{
      width: 400px !important;
      height: 600px !important;
    }
    .img-l{
      width: 400px !important;
      height: 700px !important;
    }
    .cont-button-arrow-l{
      position: absolute;
      bottom: 0;
      left: 20%;
    }
    .cont-button-arrow-r{
      position: absolute;
      bottom: 0;
      left: calc(20% + 20px);
    } 
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-n{
      width: 400px !important;
      height: 600px !important;
    }
    .img-l{
      width: 400px !important;
      height: 700px !important;
    }
    .cont-button-arrow-l{
      position: absolute;
      bottom: 0;
      left: 20%;
    }
    .cont-button-arrow-r{
      position: absolute;
      bottom: 0;
      left: calc(20% + 20px);
    }
  }
  @media(min-width: 1920px){
    .img-n{
      width: 400px !important;
      height: 600px !important;
    }
    .img-l{
      width: 400px !important;
      height: 700px !important;
    }
    .cont-button-arrow-l{
      position: absolute;
      bottom: 0;
      left: 30%;
    }
    .cont-button-arrow-r{
      position: absolute;
      bottom: 0;
      left: calc(30% + 20px);
    }
  }
`

export const ContCarousel = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 600px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`

export const ItemCarousel = styled.div`
  position: relative;
  width: 100%;
  bottom: 0;
  
  background-image: url(${props => props.bgImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
  
  /*
  &.lazy-image{
    background-image: none;
    background-color: #F1F1FA;
  } 
  */
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 380px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 810px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 600px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 700px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    height: 700px;
  }
  @media(min-width: 1920px){
    height: 1050px;
  }
`
export const ContItemsCounter = styled.div`
  margin-top: 30px;
  margin-right: 50px;
  width: 100%;
  height: auto;
  
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-right: 0;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-right: 0;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-right: 0;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-right: 75px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    margin-right: 35px;
  }
  @media(min-width: 1920px){
    
  }
`
export const ItemsCounter = styled.div`
  position: relative;
  
  width: 50px;
  
  font: 20px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    /*color: ${GlobalColors.colorPrimary};*/
  }
  @media(min-width: 768px) and (max-width: 1023px){
    /*color: ${GlobalColors.colorPrimary};*/
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
export const ItemsCounterLine = styled.div`
  position: relative;
  width: 50%;
  height: 1px;
  
  background-color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 40%;
    /*background-color: ${GlobalColors.colorPrimary};*/
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 45%;
    /*background-color: ${GlobalColors.colorPrimary};*/
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
