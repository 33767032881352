import styled, { createGlobalStyle }  from 'styled-components'
import {GlobalFonts, GlobalColors, GlobalBackgroundColors} from "../../styles/GlobalStyles";
import BetasSVG from '../../images/home-betas.svg';

export const GlobalStyle = createGlobalStyle`
  .page-home{
    height: auto;
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  .full-height{
    height: 100%;
  }
`
export const ContInicio = styled.div`
  position: relative;
  top: 80px;
  left: 80px;
  margin-bottom: 130px;
  
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 5px;
    top: 70px;
    left: 0 !important;
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
     width: 660px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 660px;
  }
  @media(min-width: 1216px) and (max-width: 1365px){
    width: 740px;
  }
  @media(min-width: 1366px) and (max-width: 1407px){
    width: 740px;
  }
  @media(min-width: 1408px){
    width: 740px;
  }
`
export const ContBienvenido = styled.div`
  margin-bottom: 20px;
  font-family: ${GlobalFonts.fontLight};
  font-size: 20px;
  color: ${GlobalColors.colorLinkHover};
`
export const ContLema = styled.div`
  width: 100%;
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 20px;
    line-height: 25px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 40px;
    line-height: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 28px;
    line-height: 38px;
  }
  @media(min-width: 1216px) and (max-width: 1365px){
    font-size: 28px;
    line-height: 38px;
  }
  @media(min-width: 1366px) and (max-width: 1407px){
    font-size: 28px;
    line-height: 38px;
  }
  @media(min-width: 1408px){
    font-size: 36px;
    line-height: 46px;
  }
`
export const ContDibujo = styled.div`
  position: relative;
  width: 40px;
  height: 120px;
  top: 30px;
  left: 30px;
`
export const ContDibujoLinea = styled.div`
  position: relative;
  width: 2px;
  height: 85px;
  margin: 0 auto;
  background-color: ${GlobalBackgroundColors.bgTertiary};
`
export const ContDibujoCirculo = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  margin: -20px auto;
  border-radius: 50%;
  border: 2px solid ${GlobalBackgroundColors.bgTertiary};
`

export const ContBetas = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background-image: url(${BetasSVG});
  background-repeat: no-repeat;
  background-size: auto;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 1750px;
    background-size: cover;
    background-position-x: 0;
    background-position-y: -220px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 2050px;
    background-size: cover;
    background-position-x: 0;
    background-position-y: -25px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding-top: 20%;
    padding-bottom: 50px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding-top: 30%;
    padding-bottom: 300px;
    background-size: auto;
  }
  @media(min-width: 1408px) and (max-width: 1599px){
    padding-top: 26%;
    padding-bottom: calc(300px + 10%);
    background-size: 100%;
  }
  @media(min-width: 1600px) and (max-width: 1799px){
    padding-top: 32%;
    padding-bottom: calc(300px + 10%);
    background-size: 100%;
  }
  @media(min-width: 1800px) and (max-width: 1919px){
    padding-top: 35%;
    padding-bottom: calc(300px + 10%);
    background-size: 100%;
  }
  @media(min-width: 1920px) and (max-width: 2304px){
    padding-top: 25%;
    padding-bottom: calc(300px + 10%);
    background-size: 100%;
  }
`
export const ContBetasDetails = styled.div`
  position: relative;
  top: 0;
  left: 20%;
  width: 70%;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: 0 auto 100px;
    top: 180px;
    left: 0;
    width: 90%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: 0 auto 440px;
    top: 440px;
    left: 0;
    width: 90%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin: 0 auto 300px;
    top: 50px;
    left: 0;
    width: 90%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
export const ImgLogoAzul = styled.img`
  position: relative;
  padding-bottom: 25px;
  top: 40%;
  src: url(${props => props.src});
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 70%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
export const ContBetasLema = styled.div`
  padding-bottom: 25px;
  width: 100%;
  font-family: ${GlobalFonts.fontRegular};
  line-height: 50px;
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
    line-height: 35px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 36px;
    line-height: 46px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 36px;
    line-height: 46px;
  }
  @media(min-width: 1216px) and (max-width: 1365px){
    font-size: 40px;
    line-height: 49px;
  }
  @media(min-width: 1366px) and (max-width: 1407px){
    font-size: 40px;
    line-height: 49px;
  }
  @media(min-width: 1408px){
    font-size: 40px;
    line-height: 49px;
  }
`
export const ContBetasDesc = styled.div`
  padding-bottom: 25px;
  width: 100%;
  font-family: ${GlobalFonts.fontRegular};
  line-height: 50px;
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 20px;
    line-height: 24px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 20px;
    line-height: 24px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
    line-height: 24px;
  }
  @media(min-width: 1216px) and (max-width: 1365px){
    font-size: 20px;
    line-height: 24px;
  }
  @media(min-width: 1366px) and (max-width: 1407px){
    font-size: 20px;
    line-height: 24px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
    line-height: 24px;
  }
`
export const ContBetasBye = styled.div`
  width: 100%;
  font-family: ${GlobalFonts.fontRegular};
  line-height: 50px;
  color: ${GlobalColors.colorSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 20px;
    line-height: 24px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 20px;
    line-height: 24px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
    line-height: 24px;
  }
  @media(min-width: 1216px) and (max-width: 1365px){
    font-size: 20px;
    line-height: 24px;
  }
  @media(min-width: 1366px) and (max-width: 1407px){
    font-size: 20px;
    line-height: 24px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
    line-height: 24px;
  }
`
export const ContSliderBetas = styled.div`
  position: relative;
  margin-bottom: 50px;
  float: right;
  
  width: 90%;
  height: 700px;
  display: flex;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 700px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 865px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    height: 1050px;
  }
`

export const ContSliderDevs = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background-color: ${GlobalBackgroundColors.bgPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-top: -200px;
    z-index: 999;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding-top: 70px;
    padding-bottom: 300px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media(min-width: 1408px){
    padding-top: 0px;
    padding-bottom: 0px;
  }
`

export const ContLoTieneTodo = styled.div`
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
export const TextoLoTieneTodo = styled.div`
  font-family: ${GlobalFonts.fontSecondary};
  
  color: ${GlobalColors.colorPrimary};
  
  & > span{
    color: ${GlobalColors.colorLinkHover};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 50px;
    line-height: 50px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 70px;
    line-height: 80px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 80px;
    line-height: 90px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 100px;
    line-height: 110px;
  }
  @media(min-width: 1408px){
    font-size: 100px;
    line-height: 110px;
  }
`
export const TextoImaginasParaiso = styled.div`
  margin-bottom: 25px;
  
  font-family: ${GlobalFonts.fontLight};  
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 20px;
    line-height: 25px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 20px;
    line-height: 25px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
    line-height: 25px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 20px;
    line-height: 25px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
    line-height: 25px;
  }
`
export const TextoDescubrelo = styled.div`
  margin-bottom: 25px;

  font-family: ${GlobalFonts.fontPrimary};
  color: ${GlobalColors.colorLinkHover};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 20px;
    line-height: 25px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 20px;
    line-height: 25px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
    line-height: 25px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 20px;
    line-height: 25px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
    line-height: 25px;
  }
`
export const ContImgTodo = styled.div`
  width: 100%;
  margin: 45px auto;
  cursor: pointer;
`
export const ImgTodo = styled.img`
  width: 100%;
`


export const ContItemLifeStyle = styled.div`
  position: relative;
  width: 100%;
  
  background-image: url(${props => props.bgImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  
  &:hover {
    &>div{
      display: flex;
    }
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    height: 380px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 600px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 555px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 555px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    height: 555px;
  }
  @media(min-width: 1920px){
    height: 555px;
  }
`
export const ContItemLifeStyleDesc = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  background-color: ${GlobalColors.colorLinkHover};
  
  opacity: 0.9;
`
export const ContItemLifeStyleTitle = styled.div`
  margin-bottom: 25px;
  font-family: ${GlobalFonts.fontLight};
  font-size: 33px;
  color: ${GlobalColors.colorMenuPrimary};
`
