import React from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import {
  ContDevActive,
  ContSlider,
  GlobalStyleSlider,
} from './styles';
import ItemSlider from "./itemSlider";
import {ContItemsCounter, ItemsCounter} from "./styles";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {GlobalColors} from '../../styles/GlobalStyles';
import imgDev1 from '../../images/oxean/ico.svg';
import imgDev2 from '../../images/punta-mar/ico.svg';
import imgDev3 from '../../images/zul/ico.svg';
import imgDevName1 from '../../images/oxean/logotipo.png';
import imgDevName2 from '../../images/punta-mar/logotipo.svg';
import imgDevName3 from '../../images/zul/logotipo.svg';

const Slider = ({data}) => {
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV
      ? <FontAwesomeIcon icon={faChevronLeft} color={GlobalColors.colorLinkHover} />
      : <FontAwesomeIcon icon={faChevronRight} color={GlobalColors.colorLinkHover} />;

    const side = type === consts.PREV ? 'l' : 'r';

    return (
      <div className={`cont-slider-arrow-${side}`}>
        <button onClick={onClick} disabled={isEdge} className={'button-arrow'}>
          {pointer}
        </button>
      </div>
    )
  }
  return (
    <ContSlider className={'cls-slider'}>
      <GlobalStyleSlider/>
      <ContDevActive/>

      <Carousel
        isRTL={false}
        itemsToScroll={1}
        itemsToShow={1}
        itemPadding={[0, 0]}
        renderArrow={myArrow}
        outerSpacing={20}
        itemPosition={consts.START}
        onChange={(currentItem, pageIndex) => {
          const indexPage = pageIndex + 1;

          const elClass = document.getElementsByClassName('img-item-carousel');
          for (let i = 0; i < elClass.length; i++) {
            elClass[i].classList.remove('img-active');
            elClass[i].classList.add('img-no-active');
          }

          const element = document.getElementById(`img-${indexPage}`);
          element.classList.remove('img-no-active');
          element.classList.add('img-active');

        }}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <ContItemsCounter>
              <ItemsCounter>
                {activePage + 1}/{pages.length}
              </ItemsCounter>
            </ContItemsCounter>
          )
        }}
      >
        <ItemSlider
          id={1}
          imageDev={imgDev1}
          imageName={imgDevName1}
          infoDesc={'Playa Bacocho, Fracc. Bacocho Puerto Escondido'}
          linkDev={'oxean'}
          infoCoordenadas={'15.8657047,-97.0924109'}
          linkMaps={'https://www.google.com/maps/place/Oxean+Puerto+Escondido/@15.8657047,-97.0924109,17z/data=!4m5!3m4!1s0x85b8f7df0b328f83:0xc9f6c1872e0a3ccd!8m2!3d15.8657047!4d-97.0902222?hl=es-MX'}
          status={'Preventa'}
        />
        <ItemSlider
          id={2}
          imageDev={imgDev2}
          imageName={imgDevName2}
          infoDesc={'Avenida Los Delfines Fracc. del Sol'}
          linkDev={'puntamar'}
          infoCoordenadas={'15.8918618,-97.1188322'}
          linkMaps={'https://www.google.com/maps?q=15.8918618,-97.1188322&hl=es-MX&gl=mx&entry=gps&lucs=s2se&shorturl=1'}
          status={'Preventa'}
        />
        <ItemSlider
          id={3}
          imageDev={imgDev3}
          imageName={imgDevName3}
          infoDesc={'Calle Ceiba, Fracc. San Agustin, Colonia Emiliano Zapata'}
          linkDev={'zul'}
          infoCoordenadas={'15.8457893,-97.0441353'}
          linkMaps={`https://www.google.com/maps/place/15°50'44.8"N+97°02'31.0"W/@15.8457893,-97.0441353,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xde9a1e88790aec14!8m2!3d15.8457893!4d-97.0419466?hl=es-MX`}
          status={'Proximamente'}
        />

      </Carousel>
    </ContSlider>
  )
}

export default Slider
